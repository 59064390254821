
import { defineComponent } from "vue";
export default defineComponent({
  metaInfo: function () {
    // for the crawler not to crawl this page
    return {
      meta:[{name: 'robots', content: 'noindex,nofollow'},{name: 'googlebot', content: 'noindex,nofollow'}]
    }
  },
  mounted() {
    document.title = "404 Not Found - K8Club";
  },
});
